import { store } from '@redux/store'
import _ from 'lodash'
import { createContext, useContext, useReducer } from 'react'

import { Utilities } from '../../../../services/utilities.service'
import { DomainAdminState } from './domain-admin__state.types'

function initialState(initialLocalState: DomainAdminState.DomainAdminData): DomainAdminState.LocalState {
	return {
		original: _.cloneDeep(initialLocalState),
		modified: _.cloneDeep(initialLocalState),
		currentRoute: 'general',
	}
}

const DomainAdminContext = createContext(
	initialState({
		general: {
			label: '',
			domainId: -1,
		},
		loginCarousel: [],
		mlsBoardOptions: [],
		referralTypeOptions: [],
		landingPageHubs: [],
		landingPageResources: [],
		chatGptSettings: {
			apiKey: '',
			listingPrompt: '',
			gptModel: '',
			maxResponseTokens: 0,
		},
		homepageModalSettings: {
			modalEnabled: false,
			title: '',
			content: '',
			buttonEnabled: false,
			buttonLabel: '',
			buttonLink: '',
			lastUpdated: 0,
			buttonAllStates: false,
			specialtyInclusion: 'ALL',
			specialtyIds: [],
			includeAllStates: true,
			includedLicensedStateIds: [],
		},
	}),
)
const DomainAdminDispatchContext = createContext({} as DomainAdminState.DispatchParams)

function DomainAdminReducer(state: DomainAdminState.LocalState, action: DomainAdminState.Action) {
	switch (action.type) {
		case 'update-general-fields': {
			let updatedState = _.cloneDeep(state)
			action.payload.forEach((fieldChange) => {
				updatedState.modified.general = {
					...updatedState.modified.general,
					[fieldChange.key]: fieldChange.value,
				}
			})
			return updatedState
		}

		case 'update-mls-board-option': {
			let updatedState = _.cloneDeep(state)

			updatedState.modified.mlsBoardOptions.forEach((option, index) => {
				if (option.mlsBoardId === action.payload.mlsBoardId) {
					updatedState.modified.mlsBoardOptions[index] = action.payload
				}
			})

			return updatedState
		}

		case 'create-mls-board-option': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.mlsBoardOptions.push({
				mlsBoardId: 0 - Utilities.generateRandomInt(1, 1000000),
				displayValue: '',
				licensedState: null,
			})
			return updatedState
		}

		case 'remove-mls-board-option': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.mlsBoardOptions = updatedState.modified.mlsBoardOptions.filter(
				(option) => option.mlsBoardId !== action.payload,
			)
			return updatedState
		}

		case 'update-login-carousel': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.loginCarousel = action.payload
			return updatedState
		}

		case 'update-referral-type-option': {
			let updatedState = _.cloneDeep(state)

			updatedState.modified.referralTypeOptions.forEach((option, index) => {
				if (option.referralTypeId === action.payload.referralTypeId) {
					updatedState.modified.referralTypeOptions[index] = action.payload
				}
			})

			return updatedState
		}

		case 'create-referral-type-option': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.referralTypeOptions.push({
				referralTypeId: 0 - Utilities.generateRandomInt(1, 1000000),
				displayValue: '',
				requireAdditionalInfo: false,
				additionalInfoLabel: null,
			})
			return updatedState
		}

		case 'remove-referral-type-option': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.referralTypeOptions = updatedState.modified.referralTypeOptions.filter(
				(option) => option.referralTypeId !== action.payload,
			)
			return updatedState
		}

		case 'set-current-route': {
			let updatedState = _.cloneDeep(state)
			updatedState.currentRoute = action.payload
			return updatedState
		}

		case 'update-landing-page-hub': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.landingPageHubs = action.payload
			return updatedState
		}

		case 'create-landing-page-hub': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.landingPageHubs.push({
				landingPageHubId: 0 - Utilities.generateRandomInt(1, 1000000),
				displayOrder: updatedState.modified.landingPageHubs.length,
				domain: store.getState().domain,
				hub: action.payload,
			})
			return updatedState
		}

		case 'remove-landing-page-hub': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.landingPageHubs = updatedState.modified.landingPageHubs.filter(
				(option) => option.landingPageHubId !== action.payload,
			)
			return updatedState
		}

		case 'update-landing-page-resource': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.landingPageResources = action.payload
			return updatedState
		}

		case 'create-landing-page-resource': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.landingPageResources.push({
				landingPageResourceId: 0 - Utilities.generateRandomInt(1, 1000000),
				displayOrder: updatedState.modified.landingPageResources.length,
				domain: store.getState().domain,
				resource: action.payload,
			})
			return updatedState
		}

		case 'remove-landing-page-resource': {
			let updatedState = _.cloneDeep(state)
			updatedState.modified.landingPageResources = updatedState.modified.landingPageResources.filter(
				(option) => option.landingPageResourceId !== action.payload,
			)
			return updatedState
		}

		case 'update-chat-gpt-settings': {
			let updatedState = _.cloneDeep(state)
			action.payload.forEach((fieldChange) => {
				updatedState.modified.chatGptSettings = {
					...updatedState.modified.chatGptSettings,
					[fieldChange.key]: fieldChange.value,
				}
			})
			return updatedState
		}
		case 'update-homepage-modal-settings': {
			let updatedState = _.cloneDeep(state)
			action.payload.forEach((fieldChange) => {
				updatedState.modified.homepageModalSettings = {
					...updatedState.modified.homepageModalSettings,
					[fieldChange.key]: fieldChange.value,
				}
			})
			return updatedState
		}
	}
}

export function DomainAdminProvider(props: {
	initialLocalState: DomainAdminState.DomainAdminData
	children: React.ReactNode
}) {
	const [state, dispatch] = useReducer(DomainAdminReducer, initialState(props.initialLocalState))

	return (
		<DomainAdminContext.Provider value={state}>
			<DomainAdminDispatchContext.Provider value={dispatch}>{props.children}</DomainAdminDispatchContext.Provider>
		</DomainAdminContext.Provider>
	)
}

export function useDomainAdmin() {
	return useContext(DomainAdminContext)
}

export function useDomainAdminDispatch() {
	return useContext(DomainAdminDispatchContext)
}
