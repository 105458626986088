import { RadioGroupOption } from '@components/radio-group/radio-group'

import { LokationSpecialty, SpecialtyInclusion } from './specialty.types'

export namespace SpecialtyService {
	export function getSpecialtiesFromIds(
		allSpecialties: LokationSpecialty[],
		specialtyIds: number[],
	): LokationSpecialty[] {
		const foundSpecialties: LokationSpecialty[] = []

		specialtyIds.forEach((id) => {
			const matchingSpecialty = allSpecialties.find((thisSpecialty) => thisSpecialty.specialtyId === id)
			if (matchingSpecialty) {
				foundSpecialties.push(matchingSpecialty)
			}
		})

		return foundSpecialties
	}

	export function getSpecialtyById(allSpecialties: LokationSpecialty[], id: number): LokationSpecialty | undefined {
		return allSpecialties.find((thisSpecialty) => thisSpecialty.specialtyId === id)
	}

	export function mapSpecialtiesToIds(specialties: LokationSpecialty[]): number[] {
		return specialties.map((specialty) => specialty.specialtyId)
	}

	export function getFilterOptions(): RadioGroupOption<SpecialtyInclusion>[] {
		return [
			{ label: 'Display if user has specialty', value: 'INCLUSIVE' },
			{ label: `Display if user does not have this specialty`, value: 'EXCLUSIVE' },
		]
	}
}
