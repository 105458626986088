import './domain.scss'

import { LoKationButton } from '@components/button/button'
import { RouteBodyV2 } from '@components/route-wrapper-with-sub-nav/route-body__v2'
import { RouteSubnavigation } from '@components/route-wrapper-with-sub-nav/route-subnavigation/route-subnavigation'
import { RouteWrapperV2 } from '@components/route-wrapper-with-sub-nav/route-wrapper__v2'
import { RootState } from '@redux/store'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ToastService } from 'src/services/toast/toast.service'

import useAdminRedirect from '../../../services/hooks/user-admin-redirect'
import { EndUserProps } from '../../../services/user/user.types'
import { settingsRoutePath } from '../../routes.constant'
import { DomainAdminService } from './domain-admin.service'
import { DomainAdminChatGptSettings } from './routes/domain-admin__chatgpt-settings'
import { DomainAdminGeneralSection } from './routes/domain-admin__general'
import { DomainAdminHomepageModalSettings } from './routes/domain-admin__homepage-modal-settings'
import { DomainAdminLandingSection } from './routes/domain-admin__landing'
import { DomainAdminLoginSection } from './routes/domain-admin__login'
import { DomainAdminRegistrationSection } from './routes/domain-admin__registration'
import { useDomainAdmin, useDomainAdminDispatch } from './state/domain-admin__state'

export type UserProfileParams = EndUserProps | null

export function DomainAdminInnerPrototype(props: { userProfile: EndUserProps | null }) {
	const navigate = useNavigate()
	useAdminRedirect(props.userProfile)

	const domainAdminState = useDomainAdmin()
	const domainAdminDispatch = useDomainAdminDispatch()

	return (
		<RouteWrapperV2>
			<RouteSubnavigation
				title={`Domain Settings`}
				backButton={{
					label: 'Administration',
					onClick: () => {
						navigate('/administration')
					},
				}}
				isCustom={false}
				routes={[
					{
						label: 'General',
						onClick: () => {
							domainAdminDispatch({ type: 'set-current-route', payload: 'general' })
						},
						isActive: domainAdminState.currentRoute === 'general',
						icon: 'cog',
						id: 'general',
					},
					{
						label: 'Login',
						onClick: () => {
							domainAdminDispatch({ type: 'set-current-route', payload: 'login' })
						},
						isActive: domainAdminState.currentRoute === 'login',
						icon: 'right-to-bracket',
						id: 'login',
					},
					{
						label: 'Landing Page',
						onClick: () => {
							domainAdminDispatch({ type: 'set-current-route', payload: 'landing' })
						},
						isActive: domainAdminState.currentRoute === 'landing',
						icon: 'home',
						id: 'landing',
					},
					{
						label: 'Registration',
						onClick: () => {
							domainAdminDispatch({ type: 'set-current-route', payload: 'registration' })
						},
						isActive: domainAdminState.currentRoute === 'registration',
						icon: 'address-card',
						id: 'registration',
					},
					{
						label: 'ChatGPT Settings',
						onClick: () => {
							domainAdminDispatch({ type: 'set-current-route', payload: 'gpt-settings' })
						},
						isActive: domainAdminState.currentRoute === 'gpt-settings',
						icon: 'microchip-ai',
						id: 'gpt-settings',
					},
					{
						label: 'Homepage Modal Settings',
						onClick: () => {
							domainAdminDispatch({ type: 'set-current-route', payload: 'modal-settings' })
						},
						isActive: domainAdminState.currentRoute === 'modal-settings',
						icon: 'window-maximize',
						id: 'modal-settings',
					},
				]}
			/>
			<RouteBodyV2 className="flex-column col-xs-12">
				{domainAdminState.modified.general && (
					<div className="flex">
						<div className="flex flex-column flex-fillSpace pl-20">
							{domainAdminState.currentRoute === 'general' && <DomainAdminGeneralSection />}
							{domainAdminState.currentRoute === 'login' && <DomainAdminLoginSection />}
							{domainAdminState.currentRoute === 'landing' && <DomainAdminLandingSection />}
							{domainAdminState.currentRoute === 'registration' && <DomainAdminRegistrationSection />}
							{domainAdminState.currentRoute === 'gpt-settings' && <DomainAdminChatGptSettings />}
							{domainAdminState.currentRoute === 'modal-settings' && <DomainAdminHomepageModalSettings />}
						</div>
					</div>
				)}

				<div className="admin-footer__save">
					<LoKationButton
						variant="outlined"
						label="Cancel"
						size="lg"
						className="mr-10"
						onClick={() => {
							navigate(`${settingsRoutePath}/`)
						}}
					/>
					<LoKationButton
						size={'lg'}
						label="Save Changes"
						onClick={() => {
							if (domainAdminState.modified.chatGptSettings) {
								if (!domainAdminState.modified.chatGptSettings.listingPrompt.includes('{address}')) {
									ToastService().create({
										type: 'ERROR',
										body: `The ChatGPT listing prompt must include {address}`,
									})
									return
								}
								if (!domainAdminState.modified.chatGptSettings.listingPrompt.includes('{reference}')) {
									ToastService().create({
										type: 'ERROR',
										body: `The ChatGPT listing prompt must include {reference}`,
									})
									return
								}
							}
							DomainAdminService()
								.saveChanges(domainAdminState)
								.then(() => {
									// navigate(`${settingsRoutePath}/`)
									ToastService().create({ type: 'SUCCESS', body: `Your settings have been updated` })
								})
						}}
						variant="contained"
					/>
				</div>
			</RouteBodyV2>
		</RouteWrapperV2>
	)
}

function mapStateToProps(state: RootState) {
	return {
		userProfile: state.user,
	}
}

export const DomainAdminInner = connect(mapStateToProps)(DomainAdminInnerPrototype)
