import { LoKationButton } from '@components/button/button'
import { DesktopAppTrayButton } from '@components/desktop-app-tray/components/app-tray__button'
import { Paper } from '@components/paper/paper'
import { ResourceCard } from '@components/resource-card/resource-card'
import { Typography } from '@components/text/text'
import { useEffect, useRef } from 'react'

import { ColorService } from '../../../services/color/color.service'
import { DomainThemeService } from '../../../services/theme/theme.service'
import { DomainTheme } from '../../../services/theme/theme.types'
import { HubFAQ } from '../../hub/hub-faq'

/** https://developers.google.com/fonts/docs/developer_api */

export function ThemeAdministrationPreviewPane(props: { theme: DomainTheme.Theme; className?: string }) {
	const completeTheme = DomainThemeService.generateThemeProps(props.theme)
	const previewPaneRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (previewPaneRef.current) {
			Object.keys(completeTheme).forEach((key, index, array) => {
				const propertyValue = completeTheme[key as keyof DomainTheme.CompleteTheme]

				if (
					previewPaneRef.current &&
					(typeof propertyValue === 'string' || typeof propertyValue === 'number')
				) {
					previewPaneRef.current.style.removeProperty(key)
					previewPaneRef.current.style.setProperty(`--${key}`, String(propertyValue))
				}
			})
		}
	}, [props.theme])

	return (
		<div
			style={{
				width: '800px',
				backgroundColor: 'var(--colorAdjustAlpha20)',
				position: 'sticky',
				top: '40px',
				height: 'calc(100vh - 140px)',
			}}
			className={`p-40 flex-noShrink flex border-radius__std ${props.className ? props.className : ''}`}
		>
			<div
				className="flex flex-column col-xs-12 xxxheight__fill"
				ref={previewPaneRef}
				style={{
					boxShadow: '0px 2px 5px rgb(0 0 0 / 30%)',
					backgroundColor: 'var(--pageBgColor)',
					color: 'var(--colorAdjust100)',
					fontSize: 'var(--bodyFontSize)',
					overflowY: 'auto',
				}}
			>
				<div
					className="col-xs-12 rel-p-2 flex flex-alignItems-center"
					style={{ backgroundColor: 'var(--appHeaderBgColor)', height: '60px' }}
				>
					<div className="flex-fillSpace flex flex-alignItems-center">
						{ColorService.getColorTone(props.theme.appHeaderBgColor) === 'dark' && (
							<img src={props.theme.logoWhite} alt="logo" height="20" />
						)}
						{ColorService.getColorTone(props.theme.appHeaderBgColor) === 'light' && (
							<img src={props.theme.logo} alt="logo" height="20" />
						)}
					</div>
				</div>

				<div className="rel-p-2 flex-fillSpace flex col-xs-12 ">
					<div
						className="destop-app-tray__visual-properties flex-noShrink rel-mr-2"
						style={{ pointerEvents: 'none' }}
					>
						{ColorService.getColorTone(props.theme.appTrayBgColor) === 'dark' && (
							<img src={props.theme.logoMarkWhite} alt="logo" className="app-tray__logo__mark rel-mb-2" />
						)}
						{ColorService.getColorTone(props.theme.appTrayBgColor) === 'light' && (
							<img src={props.theme.logoMark} alt="logo" className="app-tray__logo__mark rel-mb-2" />
						)}

						<DesktopAppTrayButton label={'Sample'} path={'/'} icon={'home'} expanded={false} />
						<DesktopAppTrayButton label={'Sample'} path={'/'} icon={'bank'} expanded={false} />
						<DesktopAppTrayButton label={'Sample'} path={'/'} icon={'book'} expanded={false} />
					</div>

					<div className="overflow-y__scroll p-10">
						<h1>Accusamus et iusto odio dignissimos</h1>
						<p>
							Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
							laudantium, totam rem aperiam, eaque ipsatem ab illo inventore veritatis et quasi architecto
							beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
							aut odit aut fugit.
						</p>
						<div className="flex my-10">
							<LoKationButton variant="contained" size="lg" label="Button" className="mr-10" />
							<LoKationButton variant="outlined" size="lg" label="Button" />
						</div>
						<div className="flex">
							<div className="col-xs-6">
								<Typography type="h6" color="secondary" margins={['bottom']}>
									Resources
								</Typography>
								<ResourceCard
									cardStyle={'STANDARD_CARD'}
									resource={{
										createdTimestamp: '1000',
										description:
											'Library of recorded training videos, available for viewing at any time',
										bgImage: '',
										tags: [],
										title: 'On-Demand Training',
										licensedStates: [],
										resourceId: -1,
										stateAgnostic: true,
										specialties: [],
										starred: false,
										hasResourcePicture: false,
										published: true,
										specialtyInclusion: 'ALL',
									}}
								/>
							</div>
							<div className="col-xs-6">
								<Typography type="h5" color="secondary" className="mb-10">
									Frequently asked questions
								</Typography>
								<Paper bgColor="primary" padding={['all']}>
									<HubFAQ
										tags={[]}
										faqId={-1}
										question="Omnis iste natus error sit voluptatem accusantium?"
										answer="Temporibus autem quibusdam et aut officiis sapiente delectus, ut aut reiciendis sit voluptatem maiores alias consequatur aut perferendis doloribus asperiores."
										licensedStates={[]}
										stateAgnostic={true}
									/>
									<HubFAQ
										tags={[]}
										faqId={-1}
										question="At vero eos et accusamus et iusto odio?"
										answer="Quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates."
										licensedStates={[]}
										stateAgnostic={true}
									/>
								</Paper>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
