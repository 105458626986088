import './filtered-option-panel.scss'

import { Checkbox } from '@components/checkbox/checkbox'
import { Paper } from '@components/paper/paper'
import { RadioGroup } from '@components/radio-group/radio-group'
import { autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react'
import { useState } from 'react'
import ReactDOM from 'react-dom'
import { SpecialtyService } from 'src/services/specialties/specialty.service'
import { FullSpecialtyInclusion, LokationSpecialty } from 'src/services/specialties/specialty.types'

export interface FilteredOptionPanelProps {
	selectedSpecialties: Array<LokationSpecialty>
	specialtyOptions: LokationSpecialty[]
	onSpecialtySelect: (selectedOptions: LokationSpecialty[]) => void
	onVisibilitySelect: (selectedVisibility: FullSpecialtyInclusion) => void
	selectedVisibility: FullSpecialtyInclusion
}

export function FilteredOptionPanel(props: FilteredOptionPanelProps) {
	const [showPanel, setShowPanel] = useState(false)

	const { refs, floatingStyles, context } = useFloating({
		open: showPanel,
		onOpenChange: setShowPanel,
		middleware: [offset(), flip(), shift()],
		placement: 'bottom-start',
		whileElementsMounted: autoUpdate,
	})

	const role = useRole(context, { role: 'dialog' })
	const dismiss = useDismiss(context)

	const { getFloatingProps } = useInteractions([role, dismiss])

	return (
		<div ref={refs.setReference} onMouseEnter={() => setShowPanel(true)} onMouseLeave={() => setShowPanel(false)}>
			<strong>Applicable Specialties</strong>
			<div>
				<Checkbox
					checked={props.selectedVisibility !== 'ALL'}
					onChange={(checked) => {
						props.onVisibilitySelect(checked ? 'INCLUSIVE' : 'ALL')
						setShowPanel(checked)
					}}
					style={{ marginBottom: '8px' }}
				>
					Filter Based on Specialty
				</Checkbox>
			</div>

			{showPanel &&
				ReactDOM.createPortal(
					<div
						ref={refs.setFloating}
						style={floatingStyles}
						className="options-panel"
						{...getFloatingProps()}
					>
						<Paper bgColor="primary" elevate={true} className="p-10 mb-20">
							<div className="filter-options p-10">
								<RadioGroup<FullSpecialtyInclusion>
									variant="SMALL"
									options={SpecialtyService.getFilterOptions()}
									optionClassName="mb-10"
									value={props.selectedVisibility}
									onChange={props.onVisibilitySelect}
								/>
							</div>

							<div className="filter-specialties p-10">
								{props.specialtyOptions.map((thisSpecialty) => {
									const isChecked = props.selectedSpecialties.includes(thisSpecialty)

									return (
										<Checkbox
											key={thisSpecialty.specialtyId}
											checked={isChecked}
											className="mb-10"
											onChange={(state) => {
												const updatedState = state
													? [...props.selectedSpecialties, thisSpecialty]
													: props.selectedSpecialties.filter((id) => id !== thisSpecialty)
												props.onSpecialtySelect(updatedState)
											}}
										>
											{thisSpecialty.specialtyName}
										</Checkbox>
									)
								})}
							</div>
						</Paper>
					</div>,
					document.body,
				)}
		</div>
	)
}
