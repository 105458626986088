import { AxiosResponse } from 'axios'

import { apiVersionURL, httpRequest, RequestBaseURL } from '../axios/http-common.service'
import { HomepageModalSettings } from './homepage-modal-settings.types'

export const DOMAIN_ID = 1

export class HomepageModalSettingsAPI {
	public static getForUser(domainId: number): Promise<AxiosResponse<HomepageModalSettings.CompleteResponse>> {
		const headers = {}
		const requestPromise = httpRequest.get(
			`${RequestBaseURL}${apiVersionURL}/user/domains/${domainId}/homepagemodal`,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static get(domainId: number): Promise<AxiosResponse<HomepageModalSettings.CompleteResponse>> {
		const headers = {}
		const requestPromise = httpRequest.get(
			`${RequestBaseURL}${apiVersionURL}/admin/domains/${domainId}/homepagemodal`,
			{
				headers,
			},
		)
		return requestPromise
	}

	public static update(
		domainId: number,
		HomepageModalSettings: HomepageModalSettings.CompleteResponse,
	): Promise<AxiosResponse<void>> {
		const headers = {}
		const requestPromise = httpRequest.put(
			`${RequestBaseURL}${apiVersionURL}/admin/domains/${domainId}/homepagemodal`,
			HomepageModalSettings,
			{
				headers,
			},
		)
		return requestPromise
	}
}
