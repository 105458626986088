import { httpRequest, RequestBaseURL } from '../axios/http-common.service'

class UtilityConnectAPIPrototype {
	async getSsoUrl(): Promise<string> {
		try {
			const params = {}
			const response = await httpRequest.get(`${RequestBaseURL}/api/v1/getUtilityConnectSsoUrl`, {
				params: params,
			})
			return response.data
		} catch (error) {
			console.log(error)
			throw new Error('Failed to get SSO URL.')
		}
	}
}

export const UtilityConnectAPI = new UtilityConnectAPIPrototype()
